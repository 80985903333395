import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import { error } from "components/common/toast/toast";

// material ui
import {
  Modal,
  Typography,
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";

// component

// icon
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// const

// img

// styles
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  pt: 5,
  pb: 3,
};

const Password = ({
  open = false,
  setOpen = () => {
    console.log("setOpen");
  },
  currentItem = null,
  setCurrentItem = () => {
    console.log("setCurrentItem");
  },
}) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [cookieSave, setCookieSave] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setCurrentItem(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === "") return false;
    setErrorPassword(false);

    // 有効期限
    const expires = 30; //Cookieの期限（1ヶ月とする）←適宜、適切な期限を設定
    let nowdate = new Date(); //現在の日付データを取得
    nowdate.setTime(nowdate.getTime() + expires * 24 * 60 * 60 * 1000); //1ヶ月後の日付データを作成
    const expiresDate = nowdate.toGMTString();

    if (cookieSave) {
      document.cookie = `pass=${password}; expires=${expiresDate}`;
    }

    rest(`Library/@default/Entry/${currentItem.Library_Entry__}`, "GET", {
      password: password,
    })
      .then((data) => {
        window.open(data.data.File[0].Url);
        handleClose();
      })
      .catch(() => {
        setErrorPassword(true);
        error("common_password_error", true, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    let cookies = document.cookie.split(";");
    let cookieObj = {};

    if (cookies.length !== 0) {
      cookies.forEach((cookieArray) => {
        const array = [cookieArray][0].split("=");
        const key = cookieArray.indexOf("=") ? array[0] : "";
        const val = cookieArray.indexOf("=") ? array[1] : "";

        if (cookieObj[key] === undefined) {
          cookieObj[key] = [val];
        } else {
          cookieObj[key].push(val);
        }
      });
    }

    if (cookieObj.pass !== undefined) {
      setCookieSave(true);
      setPassword(cookieObj.pass[0]);
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Button
          onClick={handleClose}
          sx={{
            minWidth: "auto",
            p: 0,
            cursor: "pointer",
            position: "absolute",
            top: "8px",
            right: "8px",
          }}
          variant="outlined"
        >
          <CloseIcon fontSize="small" />
        </Button>
        <Typography
          id="modal-modal-title"
          variant="subtitle1"
          component="h2"
          sx={{ fontWeight: "bold", mb: 1, textAlign: "center" }}
        >
          {t("common_password_modal_title")}
        </Typography>
        <Box>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              fullWidth
              value={password}
              error={errorPassword}
              id="outlined-basic"
              variant="outlined"
              sx={{ mb: 2 }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={showPassword ? "text" : "password"}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              style={{
                marginBottom: "20px",
              }}
              control={
                <Checkbox
                  onChange={(e) => {
                    setCookieSave(e.target.checked);
                  }}
                  checked={cookieSave}
                  name="checkedB"
                  color="primary"
                />
              }
              label={t("common_back_number_pass_save")}
            />
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                disabled={password === ""}
                onClick={handleSubmit}
              >
                {t("common_password_modal_btn")}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  );
};

export default Password;
