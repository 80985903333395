import React from "react";

// material ui

// component
import Liner from "./section/liner";
import Headlines from "./section/Headlines";
import Hero from "./section/Hero";

// const

// img

// styles

const Index = () => {
  return (
    <>
      <Hero />
      <Liner />
      <Headlines />
    </>
  );
};

export default Index;
