import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// component
import ContentsTitle from "components/common/Title/ContentsTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import PageCms from "components/common/Page/PageCms";

// const

// img

// styles

const Researcher = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("研究所員2"),
    },
    true
  );

  return (
    <>
      {article !== false && (
        <ContentsBox>
          <ContentsTitle title={t("researcher_title")} />
          <PageCms article={article} />
        </ContentsBox>
      )}
    </>
  );
};

export default Researcher;
