export const MAIN_IMG_VARIATION = [
  "resolution=200&background_color=white&flatten&strip&format=jpeg&autocrop=568x568",
];
export const TOP_LINER_PARAM = {
  sort: "date:desc",
  image_variation: MAIN_IMG_VARIATION,
  results_per_page: 9,
  page_no: 1,
};

export const TOP_HEADLINES_PARAM = {
  sort: "date:desc",
  results_per_page: 100,
  page_no: 1,
};

export const BACK_LINER_PARAM = {
  sort: "date:desc",
  results_per_page: 30,
};
