import React from "react";

// material ui
import { Box, IconButton, Button } from "@mui/material";

// icon
import MenuIcon from "@mui/icons-material/Menu";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";

// conponents
import Menus from "./Menus";

// img
import jijiLogo from "assets/img/img-logo.svg";

const Nav = ({
  openMenu = false,
  setOpenMenu = () => {
    console.log("ok");
  },
}) => {
  return (
    <Box>
      <Box sx={{ display: { xs: "none", hd: "block" }, textAlign: "right" }}>
        <Menus />
        <Button
          href="https://www.jiji.com/"
          target="_blank"
          sx={{
            ml: 3,
            display: { xs: "block", sm: "inline-flex" },
            color: "#fff",
          }}
        >
          <img src={jijiLogo} width="90px" alt="" />
          <OpenInNewIcon sx={{ ml: "3px" }} fontSize="small" color="primary" />
        </Button>
      </Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        sx={{
          display: { xs: "flex", hd: "none" },
          height: "40px",
          width: "40px",
          p: 0,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {!openMenu ? <MenuIcon /> : <CloseIcon />}
      </IconButton>
    </Box>
  );
};

export default Nav;
