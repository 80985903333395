import React from "react";
import { getLocale } from "@karpeleslab/klbfw";
import ReactHelmet from "react-helmet";
import { useTranslation } from "react-i18next";

const Helmet = (props) => {
  const { t } = useTranslation();

  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      <title>{t("common_title")}</title>
      <meta name="description" content={t("common_meta_desc")} />
      <link rel="canonical" href="xxxxxx" />
      <meta property="og:locale" content={getLocale().replace("-", "_")} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={t("ccommon_meta_title")} />
      <meta property="og:description" content={t("common_meta_desc")} />
      <meta property="og:url" content="xxxxxx" />
      <meta property="og:site_name" content={t("common_meta_desc")} />
      {props.children}
    </ReactHelmet>
  );
};

export default Helmet;
