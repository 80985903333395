import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import moment from "moment";
import queryString from "query-string";

// material ui
import { Button } from "@mui/material";

//icon

// component

// const

// img

// styles
import styles from "./search.module.scss";

const SearchForm = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [from, setFrom] = useState(
    params.from === void 0
      ? moment().subtract(1, "month").format("YYYY-MM-DD")
      : params.from
  );
  const [to, setTo] = useState(
    params.to === void 0 ? moment().format("YYYY-MM-DD") : params.to
  );
  const [keyword, setKeyword] = useState(
    params.keyword === void 0 ? "" : params.keyword
  );
  const [query, setQuery] = useState("");

  useEffect(() => {
    let query = {
      p: 1,
    };

    if (to !== null) {
      query.to = to;
    }
    if (from !== null) {
      query.from = from;
    }
    if (keyword !== "") {
      query.keyword = keyword;
    }
    setQuery(`?${queryString.stringify(query)}`);
  }, [to, from, keyword]);

  return (
    <>
      <div className={styles["form-wrapper"]}>
        <dl className={styles["form-inner"]}>
          <div className={styles["form-item"]}>
            <dt className={styles["form-label"]}>期間を指定</dt>
            <dd
              className={`${styles["form-body"]} ${styles["form-body-fromto"]}`}
            >
              <input
                className={styles["form-body-input"]}
                type="date"
                placeholder="YYYY/MM/DD"
                value={from}
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
              />
              <span className={styles["form-child"]}>~</span>
              <input
                className={styles["form-body-input"]}
                type="date"
                placeholder="YYYY/MM/DD"
                value={to}
                onChange={(e) => {
                  setTo(e.target.value);
                }}
              />
            </dd>
          </div>
          <div className={styles["form-item"]}>
            <dt className={styles["form-label"]}>キーワード入力</dt>
            <dd className={styles["form-body"]}>
              <input
                className={styles["form-body-input"]}
                type="text"
                placeholder="キーワード入力"
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
            </dd>
          </div>
        </dl>

        <Button
          className={styles["form-btn"]}
          component={Link}
          to={`/comment-liner/search${query}`}
          variant="contained"
        >
          検索
        </Button>
      </div>
    </>
  );
};

export default SearchForm;
