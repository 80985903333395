import React, { useEffect, useState } from "react";
import { rest } from "@karpeleslab/klbfw";

// material ui
import { Box, Typography } from "@mui/material";

// component
import Loading from "../Loading/Loading";

// icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// const
import { MAIN_IMG_VARIATION } from "const/const";

// img
import imgDefault from "assets/img/liner_default.jpg";

// styles
import styles from "./Liner.module.scss";

const TopListItem = ({ item = null }) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  const buildImg = (img) => {
    let imgdef = imgDefault;

    if (img !== null) {
      imgdef = img.Variation[MAIN_IMG_VARIATION];
    }
    return imgdef;
  };

  useEffect(() => {
    rest(
      `Library/lib-dhefip-jw4f-gota-pnog-kcnjud5y/Entry/${item.Library_Entry__}`,
      "GET",
      {}
    ).then((data) => {
      setPdfUrl(data.data.File[0].Url);
    });
  }, [item]);

  return (
    <>
      {pdfUrl === null && (
        <Box className={styles["liner-item"]}>
          <Loading />
        </Box>
      )}
      {pdfUrl !== null && (
        <a
          className={styles["liner-item"]}
          target="_blank"
          href={pdfUrl}
          rel="noreferrer"
        >
          <figure className={styles["liner-item-figure"]}>
            <img
              className={styles["liner-item-img"]}
              src={buildImg(item.Image)}
              alt={item.Title.replace(".pdf", "")}
            />
          </figure>
          <Typography color="primary">
            {item.Title.replace(".pdf", "")}
          </Typography>
          <Box className={styles["liner-item-icon-wrapper"]}>
            <PictureAsPdfIcon
              color="error"
              fontSize="large"
              className={styles["liner-item-icon"]}
            />
          </Box>
        </a>
      )}
    </>
  );
};

export default TopListItem;
