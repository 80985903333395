import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import { Box, Typography } from "@mui/material";

// component

// icon

// const

// img

// styles

const ListNotFound = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ textAlign: "center", mb: 6 }}>
      <Typography
        variant="subtitle1"
        component="p"
        dangerouslySetInnerHTML={{ __html: t("common_list_notfound_text") }}
      />
    </Box>
  );
};

export default ListNotFound;
