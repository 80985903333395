import React from "react";
import clsx from "clsx";

// material ui
import CircularProgress from "@mui/material/CircularProgress";

// style
import styles from "./Loading.module.scss";

const Loading = ({ className = null }) => {
  return (
    <div
      className={clsx(styles["loading-container"], {
        [className]: className !== null,
      })}
    >
      <CircularProgress className={styles["loading"]} color="secondary" />
    </div>
  );
};

export default Loading;
