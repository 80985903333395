import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// material ui
import { Box, Typography } from "@mui/material";

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import ContentsTitle from "components/common/Title/ContentsTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import ExecutiveIntroduction from "./section/ExecutiveIntroduction";
import Researcher from "./section/Researcher";

// const

// img

// styles

const AboutUs = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("Aboutイメージ"),
    },
    true
  );

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (article !== null) {
      setImg(
        article.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
      );
    }
  }, [article]);

  return (
    <>
      <Helmet>
        <title>{`${t("about_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("about_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("about_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath paths={[{ path: "/about-us", name: t("about_title") }]} />
        {img !== null && <PageTitle title={t("about_title")} bgimg={img} />}
        <ContentsBox backgound={true}>
          <ContentsTitle title={t("about_title_sub")} />
          <Typography
            variant="body1"
            component="p"
            dangerouslySetInnerHTML={{ __html: t("about_text") }}
          />
        </ContentsBox>
        <ExecutiveIntroduction />
        <Researcher />
      </Box>
    </>
  );
};

export default AboutUs;
