import React from "react";

// material ui
import { Box, Typography, Link } from "@mui/material";

// icon
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// component

// const

// img

// styles
import styles from "./Title.module.scss";

const ContentsTitle = ({
  title = "コンテンツタイトル",
  variant = "h5",
  component = "h2",
  helperText = null,
  sx = null,
  img = null,
  linkUrl = null,
}) => {
  return (
    <>
      <Box sx={sx === null ? { mb: 5 } : sx}>
        <Box className={styles["content-title"]}>
          <Box className={styles["content-title-inner"]}>
            {img !== null && <img src={img} style={{ marginRight: "10px" }} />}
            <Typography
              className={styles["content-title-text"]}
              variant={variant}
              component={component}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Box>
        </Box>
        {helperText !== null && (
          <>
            {linkUrl !== null && (
              <div style={{ textAlign: "center" }}>
                <Link
                  href={linkUrl}
                  target="_blank"
                  className={styles["content-title-btn"]}
                >
                  <Typography
                    className={styles["content-title-helper-btn"]}
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{ __html: helperText }}
                    sx={{ textAlign: "center" }}
                  />
                  <OpenInNewIcon fontSize="small" color="primary" />
                </Link>
              </div>
            )}
            {linkUrl === null && (
              <Typography
                className={styles["content-title-helper"]}
                variant="body2"
                component="p"
                dangerouslySetInnerHTML={{ __html: helperText }}
                sx={{ textAlign: "center" }}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default ContentsTitle;
