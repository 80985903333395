import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui
import { Button } from "@mui/material";

// icon

// conponents

const SpMenus = ({
  color = "primary",
  openMenu = false,
  setOpenMenu = () => {
    console.log("ok");
  },
}) => {
  const { t } = useTranslation();

  const styleArray = { fontSize: "18px", display: "block" };

  return (
    <>
      <Button
        color={color}
        component={Link}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        to="/"
        sx={styleArray}
      >
        {t("common_home")}
      </Button>
      <Button
        color={color}
        component={Link}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        to="/about-us"
        sx={styleArray}
      >
        {t("common_about")}
      </Button>
      <Button
        color={color}
        component={Link}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        to="/news"
        sx={styleArray}
      >
        {t("common_news")}
      </Button>
      <Button
        color={color}
        component={Link}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        to="/comment-liner"
        sx={styleArray}
      >
        {t("common_comment_liner")}
      </Button>
      <Button
        color={color}
        component={Link}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        to="/comment-liner/back-number"
        sx={styleArray}
      >
        {t("common_back_number")}
      </Button>
      <Button
        color={color}
        component={Link}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        to="/information"
        sx={styleArray}
      >
        {t("common_info")}
      </Button>
    </>
  );
};

export default SpMenus;
