import React from "react";

// material ui
import { Box, Typography } from "@mui/material";

// icon

// component

// const

// img
import bgImg from "assets/img/bg-page-title.jpg";

// styles
import styles from "./Title.module.scss";

const PageTitle = ({
  title = "コンテンツタイトル",
  subTitle = null,
  variant = "h3",
  component = "h1",
  bgimg = null,
}) => {
  return (
    <>
      <Box>
        <Box
          sx={{
            backgroundImage: `url(${
              bgimg === null || bgimg === false ? bgImg : bgimg
            })`,
            px: 2,
          }}
          className={styles["page-title"]}
        >
          <Box className={styles["page-title-inner"]}>
            <Typography
              color="secondary"
              className={styles["page-title-text"]}
              variant={variant}
              component={component}
              dangerouslySetInnerHTML={{ __html: title }}
              sx={{ fontSize: { xs: "1.5rem", sm: "3rem" } }}
            />
            <Typography
              color="secondary"
              className={styles["page-title-text"]}
              variant="body2"
              component="p"
              dangerouslySetInnerHTML={{ __html: subTitle }}
            />
          </Box>
          <Box className={styles["page-title-overlay"]}></Box>
        </Box>
      </Box>
    </>
  );
};

export default PageTitle;
