import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl", "hd"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1080,
      xl: 1920,
      hd: 935,
    },
  },
  palette: {
    primary: {
      main: "#001d55",
    },
    secondary: {
      main: "#ffffff",
      contrastText: "rgba(255, 255, 255, 0.87)",
    },
  },
});
