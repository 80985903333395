import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// material ui
import { Box, Typography, Button } from "@mui/material";

// icon

// component
import Helmet from "components/common/Helmet/Helmet";
import PageTitle from "components/common/Title/PageTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";

// const

// img

// styles

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{`${t("404_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("404_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("404_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <PageTitle title={t("404_title")} />
        <ContentsBox>
          <Typography
            variant="h5"
            component="h2"
            dangerouslySetInnerHTML={{ __html: t("404_title") }}
            sx={{ mb: 0, textAlign: "center" }}
          />
          <Typography
            variant="body1"
            component="p"
            dangerouslySetInnerHTML={{ __html: t("404_title_sub") }}
            sx={{ mb: 4, textAlign: "center" }}
          />
          <Box sx={{ mb: 0, textAlign: "center" }}>
            <Button
              variant="contained"
              sx={[
                { py: 2, px: 6, fontSize: "20px" },
                { "&:hover": { opacity: ".6" } },
              ]}
              component={Link}
              to="/"
            >
              {t("404_btn")}
            </Button>
          </Box>
        </ContentsBox>
      </Box>
    </>
  );
};

export default NotFound;
