import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

// material ui
import { Pagination } from "@mui/material";

// icon
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

// style
import styles from "./Pagination.module.scss";

const Paging = ({ count = 0, defaultPage = 1, setCurrentPage = null }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const params = queryString.parse(location.search);

  const [page] = useState(params.p === void 0 ? 1 : parseInt(params.p));
  const pageChange = (event, page) => {
    setCurrentPage(page);

    let query = params;

    query.p = page;

    history.push(`${location.pathname}?${queryString.stringify(query)}`);
  };

  return (
    <div className={styles["pagination-container"]}>
      <div className={styles["pagination"]}>
        {count !== 0 && (
          <>
            <button
              onClick={() => {
                pageChange(null, parseInt(defaultPage) - 1);
                window.scrollTo(0, 0);
              }}
              className={styles["pagination-btn"]}
              disabled={parseInt(defaultPage) === 1}
            >
              <ArrowBackIos />
              {t("paginate_prev")}
            </button>
            <Pagination
              className={styles["pagination-list"]}
              defaultPage={parseInt(page)}
              page={parseInt(defaultPage)}
              onChange={(event, page) => {
                pageChange(event, page);
                window.scrollTo(0, 0);
              }}
              count={count}
              hideNextButton={true}
              hidePrevButton={true}
            />
            <button
              onClick={() => {
                pageChange(null, parseInt(defaultPage) + 1);
                window.scrollTo(0, 0);
              }}
              className={styles["pagination-btn"]}
              disabled={parseInt(defaultPage) === count}
            >
              {t("paginate_next")}
              <ArrowForwardIos />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Paging;
