import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// material ui
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";

// icon
import FaxIcon from "@mui/icons-material/Fax";

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import ContentsTitle from "components/common/Title/ContentsTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import PageCms from "components/common/Page/PageCms";

// const

// img
import titleImg from "assets/img/img_comment_liner_title.jpg";

// styles
import styles from "./CommentLiner.module.scss";

const CommentLiner = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("執筆者一覧"),
    },
    true
  );

  const [imgArticle] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("コメントライナーイメージ"),
    },
    true
  );

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (imgArticle !== null) {
      setImg(
        imgArticle.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
      );
    }
  }, [imgArticle]);

  return (
    <>
      <Helmet>
        <title>{`${t("comment_liner_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("comment_liner_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("comment_liner_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath
          paths={[{ path: "/comment-liner", name: t("comment_liner_title") }]}
        />
        {img !== null && (
          <PageTitle title={t("comment_liner_title")} bgimg={img} />
        )}
        <ContentsBox>
          <ContentsTitle img={titleImg} title={t("comment_liner_title_sub")} />
          <Typography
            variant="body1"
            component="p"
            dangerouslySetInnerHTML={{ __html: t("comment_liner_info_text") }}
            sx={{ mb: 6 }}
          />
          <ContentsTitle
            sx={{ mb: 2 }}
            variant="h6"
            component="h3"
            title={t("comment_liner_features_title")}
          />
          <List sx={{ listStyleType: "disc", pl: 3 }}>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary={t("comment_liner_features_01")} />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary={t("comment_liner_features_02")} />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary={t("comment_liner_features_03")} />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary={t("comment_liner_features_04")} />
            </ListItem>
          </List>
        </ContentsBox>
        <ContentsBox backgound={true}>
          <ContentsTitle title={t("comment_liner_trial_title")} />
          <Box
            className={`${styles["comment-liner-btn-wrapper"]} ${styles["comment-liner-btn-wrapper-center"]}`}
            sx={{ mb: 6 }}
          >
            <Button
              className={styles["comment-liner-btn"]}
              variant="contained"
              component={Link}
              to="/trial"
              sx={[
                { py: 2, px: 6, fontSize: "20px" },
                { "&:hover": { opacity: ".6" } },
              ]}
            >
              {t("common_trial_form")}
            </Button>
          </Box>
          <Typography
            variant="subtitle1"
            component="p"
            dangerouslySetInnerHTML={{
              __html: t("comment_liner_trial_text_fax"),
            }}
            sx={{ mb: 4, textAlign: "center" }}
          />
          <Box
            className={`${styles["comment-liner-btn-wrapper"]} ${styles["comment-liner-btn-wrapper-center"]}`}
            sx={{ mb: 0 }}
          >
            <Button
              className={styles["comment-liner-btn"]}
              variant="contained"
              startIcon={<FaxIcon />}
              sx={[
                { py: 2, px: 6, fontSize: "20px" },
                { "&:hover": { opacity: ".6" } },
              ]}
              component="a"
              href="https://jri.jiji.com/commentliner_chirashi_202204.pdf"
              target="_blank"
            >
              {t("comment_liner_trial_fax")}
            </Button>
          </Box>
        </ContentsBox>
        {article !== false && (
          <ContentsBox>
            <ContentsTitle
              sx={{ mb: 1 }}
              title={t("comment_liner_author_title")}
            />
            <PageCms article={article} />
          </ContentsBox>
        )}
      </Box>
    </>
  );
};

export default CommentLiner;
