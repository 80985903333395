import React from "react";

// material ui
import { Box, Typography } from "@mui/material";

// component
import Loading from "../Loading/Loading";
import ListNotFound from "../NotFound/ListNotFound";

// icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// const

// img

// styles

const BackNumberList = ({
  library = [],
  setOpen = () => {
    console.log("setOpen");
  },
  setCurrentItem = () => {
    console.log("setCurrentItem");
  },
}) => {
  const handlePassward = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };

  return (
    <>
      {library === null && <Loading />}
      {library !== null && library?.length === 0 && <ListNotFound />}
      {library !== null && library?.length > 0 && (
        <Box>
          {library.map((item, index) => {
            return (
              <Box
                key={index}
                sx={[
                  {
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    p: 2,
                    transition: ".6s",
                  },
                  {
                    "&:hover": {
                      background: "#f6f6f6",
                    },
                  },
                ]}
                onClick={() => {
                  handlePassward(item);
                }}
              >
                <PictureAsPdfIcon
                  color="error"
                  fontSize="large"
                  sx={{
                    mr: 2,
                  }}
                />
                <Typography color="primary">
                  {item.Title.replace(".pdf", "")}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};

export default BackNumberList;
