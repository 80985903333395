import React from "react";
import { Link } from "react-router-dom";

// material ui
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

// icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// component

// utils
import { buildDate } from "utils/BuildDate";

// const

// img

// styles

const NewsList = ({ news = null, page = null, param = "news" }) => {
  return (
    <List sx={{ borderTop: "1px solid #ccc", pt: 0 }}>
      {news.data.data.map((item, index) => {
        return (
          <ListItem key={index} sx={{ borderBottom: "1px solid #ccc", p: 0 }}>
            <ListItemButton
              component={Link}
              to={{
                pathname: `/${param}/${item.Slug}`,
                state: { p: `?p=${page}` },
              }}
            >
              <ListItemText
                primary={item.Title}
                secondary={buildDate(
                  parseInt(item.Published.unixms),
                  "YYYY/MM/DD"
                )}
              />
              <ListItemIcon
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default NewsList;
