import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// material ui
import { Box, Typography } from "@mui/material";

// component
import Loading from "components/common/Loading/Loading";

// const

// img
import mainImg from "assets/img/bg-main.jpg";

// styles
import styles from "../Index.module.scss";

const Hero = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("Top%E3%82%A4%E3%83%A1%E3%83%BC%E3%82%B8"),
    },
    true
  );

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (article !== null) {
      setImg(
        article.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
      );
    }
  }, [article]);

  return (
    <>
      <Box
        className={styles["hero"]}
        sx={{
          width: "100%",
          mb: 0,
        }}
      >
        {img === null && <Loading />}
        {img !== null && (
          <>
            <img
              className={styles["hero-img"]}
              src={img !== null ? img : mainImg}
              alt=""
            />
            <Typography
              className={styles["hero-text"]}
              variant="h4"
              component="h2"
              dangerouslySetInnerHTML={{ __html: t("common_hero_title") }}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default Hero;
