import React from "react";

// material ui
import { Box } from "@mui/material";

// component

// icon

// const

// img

// styles
import styles from "./ContentsBox.module.scss";

const ContentsBox = ({
  children,
  sx = { pt: 6, pb: 10 },
  backgound = false,
}) => {
  const styleSet = !backgound ? "" : styles["contents-box-blue"];

  return (
    <>
      <Box className={styleSet} sx={sx}>
        <Box className={styles["contents-box-inner"]} sx={{ px: 2 }}>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default ContentsBox;
