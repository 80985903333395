import React from "react";

// material ui
import { Box } from "@mui/material";

// component
import Loading from "../Loading/Loading";
import ListNotFound from "../NotFound/ListNotFound";
import TopListItem from "./TopListItem";

// icon

// const

// img

// styles
import styles from "./Liner.module.scss";

const TopList = ({ library = [] }) => {
  return (
    <>
      {library === null && <Loading />}
      {library !== null && library?.length === 0 && <ListNotFound />}
      {library !== null && library?.length > 0 && (
        <Box className={styles["liner-wrapper"]}>
          {library.map((item, index) => {
            return <TopListItem item={item} key={index} />;
          })}
        </Box>
      )}
    </>
  );
};

export default TopList;
