import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

// material ui
import { Box } from "@mui/material";

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import NewsList from "components/common/News/NewsList";
import Paging from "components/common/Pagination/Pagination";
import Loading from "components/common/Loading/Loading";
import ContentsBox from "components/common/ContentsBox/ContentBox";

// const

// img

// styles

const Information = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = queryString.parse(location.search);

  // page
  const [page, setPage] = useState(
    params.p === void 0 ? 1 : parseInt(params.p)
  );

  const [news] = useRest(
    "Content/Cms/@news:search",
    {
      results_per_page: 10,
      sort: "weight:desc,published:desc",
      page_no: page,
      query: {
        tag: "info",
      },
    },
    true
  );

  useEffect(() => {
    setPage(params.p === void 0 ? 1 : parseInt(params.p));
  }, [location]);

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("お知らせイメージ"),
    },
    true
  );

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (article !== null) {
      setImg(
        article.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
      );
    }
  }, [article]);

  return (
    <>
      <Helmet>
        <title>{`${t("common_info")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_info")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_info")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath paths={[{ path: "/information", name: t("common_info") }]} />

        {img !== null && <PageTitle title={t("common_info")} bgimg={img} />}
        <ContentsBox>
          {news === null && <Loading />}
          {news !== null && news !== false && (
            <>
              <NewsList page={page} news={news} param="information" />
              <Paging
                setCurrentPage={setPage}
                defaultPage={page}
                count={news.paging?.page_max}
              />
            </>
          )}
        </ContentsBox>
      </Box>
    </>
  );
};

export default Information;
