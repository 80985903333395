import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

// material ui
import { Box, Link } from "@mui/material";

// icon

// component

// const

// img

// styles
import styles from "./TopicPath.module.scss";

const TopicPath = ({ paths = [{ path: "/", name: "test" }] }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box className={styles["path-wrapper"]} sx={{ px: 2, py: 1 }}>
        <Link className={styles["path-item"]} component={RouterLink} to="/">
          {t("common_home")}
        </Link>
        {paths.map((item, index) => {
          return (
            <Link
              key={index}
              className={styles["path-item"]}
              component={RouterLink}
              to={item.path}
            >
              {item.name}
            </Link>
          );
        })}
      </Box>
    </>
  );
};

export default TopicPath;
