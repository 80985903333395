import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import { error } from "components/common/toast/toast";

// material ui
import { Box, Typography, Button } from "@mui/material";

//icon

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";

// const

// img

// styles
import styles from "./Trial.module.scss";

const Trial = () => {
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [kana, setKana] = useState("");
  const [corporateName, setCorporateName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [memoOther, setMemoOther] = useState("");
  const [questionnaire, setQuestionnaire] = useState(
    t("common_radio_1_questionnaire")
  );
  const [other, setOther] = useState("");
  const [memo, setMemo] = useState("");
  const [disabeld, setDisabled] = useState(true);
  const [todo, setTodo] = useState(false);
  const [sendComp, setSendComp] = useState(false);

  useEffect(() => {
    const ifParam =
      questionnaire === t("common_radio_5_questionnaire")
        ? name === "" ||
          kana === "" ||
          corporateName === "" ||
          address === "" ||
          phone === "" ||
          email === "" ||
          memoOther === "" ||
          other === ""
        : name === "" ||
          kana === "" ||
          corporateName === "" ||
          address === "" ||
          phone === "" ||
          email === "" ||
          memoOther === "" ||
          questionnaire === "";

    const nameText = `【${t("common_label_name")}】\n${name}`;
    const kanaText = `【${t("common_label_kana")}】\n${kana}`;
    const corporateNameText = `【${t(
      "common_label_corporate"
    )}】\n${corporateName}`;
    const addressText = `【${t("common_label_address")}】\n${address}`;
    const phoneText = `【${t("common_label_phone")}】\n${phone}`;
    const emailText = `【${t("common_label_email")}】\n${email}`;
    const memoOtherText = `【${t("common_label_other")}】\n${memoOther}`;
    const questionnaireText = `【${t("common_label_questionnaire")}】\n${
      questionnaire === t("common_radio_5_questionnaire")
        ? other
        : questionnaire
    }`;

    setMemo(
      `${t(
        "common_trial_form_mail_text"
      )}\n\n${nameText}\n\n${kanaText}\n\n${corporateNameText}\n\n${addressText}\n\n${phoneText}\n\n${emailText}\n\n${memoOtherText}\n\n${questionnaireText}`
    );

    if (ifParam) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [
    name,
    kana,
    corporateName,
    address,
    phone,
    email,
    memoOther,
    questionnaire,
    other,
  ]);

  const formReset = () => {
    setName("");
    setKana("");
    setCorporateName("");
    setAddress("");
    setPhone("");
    setEmail("");
    setQuestionnaire(t("common_radio_1_questionnaire"));
    setOther("");
  };

  const formSubmit = () => {
    setTodo(true);

    const query = {
      Email: email,
      Name: name,
      To: "@support",
      Subject: "「コメントライナー」フリートライアルの申し込みがありました",
      Message: memo,
    };

    rest("Support/Ticket", "POST", query)
      .then(() => {
        setTodo(false);
        setSendComp(true);
      })
      .catch((err) => {
        setTodo(false);
        error(t(`error_${err.token}`), false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <>
      <Helmet>
        <title>{`${t("common_trial")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("common_trial")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("common_trial")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath
          paths={[
            {
              path: "/comment-liner/back-number",
              name: t("common_trial"),
            },
          ]}
        />
        <PageTitle title={t("common_trial")} />
        {sendComp && (
          <>
            <ContentsBox>
              <Typography
                variant="h5"
                component="h2"
                dangerouslySetInnerHTML={{
                  __html: t("common_comp_trial_form"),
                }}
                sx={{ mb: 4, textAlign: "center" }}
              />
              <p
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: t("common_comp_trial_text"),
                }}
              />

              <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                <Button variant="contained" href="/">
                  {t("404_btn")}
                </Button>
              </Box>
            </ContentsBox>
          </>
        )}
        {!sendComp && (
          <>
            <ContentsBox>
              <Typography
                variant="h5"
                component="h2"
                dangerouslySetInnerHTML={{
                  __html: t("common_title_trial_form"),
                }}
                sx={{ mb: 4, textAlign: "center" }}
              />
              <dl>
                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_name")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <input
                      className={styles["form-body-input"]}
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      disabled={todo}
                    />
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_kana")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <input
                      className={styles["form-body-input"]}
                      type="text"
                      value={kana}
                      onChange={(e) => {
                        setKana(e.target.value);
                      }}
                      disabled={todo}
                    />
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_corporate")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <input
                      className={styles["form-body-input"]}
                      type="text"
                      value={corporateName}
                      onChange={(e) => {
                        setCorporateName(e.target.value);
                      }}
                      disabled={todo}
                    />
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_address")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <textarea
                      className={styles["form-body-textarea"]}
                      type="text"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      disabled={todo}
                    ></textarea>
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_phone")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <input
                      className={styles["form-body-input"]}
                      type="phone"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      disabled={todo}
                    />
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_email")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <input
                      className={styles["form-body-input"]}
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      disabled={todo}
                    />
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_other")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <textarea
                      className={styles["form-body-textarea"]}
                      type="text"
                      value={memoOther}
                      onChange={(e) => {
                        setMemoOther(e.target.value);
                      }}
                      disabled={todo}
                    ></textarea>
                  </dd>
                </div>

                <div className={styles["form-item"]}>
                  <dt className={styles["form-label"]}>
                    {t("common_label_questionnaire")}
                  </dt>
                  <dd className={styles["form-body"]}>
                    <p className={styles["form-body-subtitle"]}>
                      {t("common_subtitle_questionnaire")}
                    </p>

                    <ul className={styles["form-body-list"]}>
                      <li className={styles["form-body-list-item"]}>
                        <label>
                          <input
                            type="radio"
                            name="questionnaire"
                            value={t("common_radio_1_questionnaire")}
                            onChange={(e) => {
                              setQuestionnaire(e.target.value);
                            }}
                            checked={
                              questionnaire ===
                              t("common_radio_1_questionnaire")
                            }
                            className={styles["form-body-radio"]}
                            disabled={todo}
                          />
                          {t("common_radio_1_questionnaire")}
                        </label>
                      </li>
                      <li className={styles["form-body-list-item"]}>
                        <label>
                          <input
                            type="radio"
                            name="questionnaire"
                            value={t("common_radio_2_questionnaire")}
                            onChange={(e) => {
                              setQuestionnaire(e.target.value);
                            }}
                            checked={
                              questionnaire ===
                              t("common_radio_2_questionnaire")
                            }
                            className={styles["form-body-radio"]}
                            disabled={todo}
                          />
                          {t("common_radio_2_questionnaire")}
                        </label>
                      </li>
                      <li className={styles["form-body-list-item"]}>
                        <label>
                          <input
                            type="radio"
                            name="questionnaire"
                            value={t("common_radio_3_questionnaire")}
                            onChange={(e) => {
                              setQuestionnaire(e.target.value);
                            }}
                            checked={
                              questionnaire ===
                              t("common_radio_3_questionnaire")
                            }
                            className={styles["form-body-radio"]}
                            disabled={todo}
                          />
                          {t("common_radio_3_questionnaire")}
                        </label>
                      </li>
                      <li className={styles["form-body-list-item"]}>
                        <label>
                          <input
                            type="radio"
                            name="questionnaire"
                            value={t("common_radio_4_questionnaire")}
                            onChange={(e) => {
                              setQuestionnaire(e.target.value);
                            }}
                            checked={
                              questionnaire ===
                              t("common_radio_4_questionnaire")
                            }
                            className={styles["form-body-radio"]}
                            disabled={todo}
                          />
                          {t("common_radio_4_questionnaire")}
                        </label>
                      </li>
                      <li className={styles["form-body-list-item"]}>
                        <label>
                          <input
                            type="radio"
                            name="questionnaire"
                            value={t("common_radio_5_questionnaire")}
                            onChange={(e) => {
                              setQuestionnaire(e.target.value);
                            }}
                            checked={
                              questionnaire ===
                              t("common_radio_5_questionnaire")
                            }
                            className={styles["form-body-radio"]}
                            disabled={todo}
                          />
                          {t("common_radio_5_questionnaire")}
                          <input
                            className={styles["form-body-input-other"]}
                            type="text"
                            value={other}
                            onChange={(e) => {
                              setOther(e.target.value);
                            }}
                            disabled={
                              questionnaire !==
                                t("common_radio_5_questionnaire") || todo
                            }
                          />
                        </label>
                      </li>
                    </ul>
                  </dd>
                </div>
              </dl>
            </ContentsBox>

            <ContentsBox backgound={true}>
              <Typography
                variant="h5"
                component="h2"
                dangerouslySetInnerHTML={{
                  __html: t("common_title_trial_attention"),
                }}
                sx={{ mb: 1, textAlign: "center" }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("common_text_trial_attention"),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("common_text_trial_attention2"),
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("common_text_trial_attention3"),
                }}
              />
            </ContentsBox>

            <ContentsBox>
              <Typography
                variant="h5"
                component="h2"
                dangerouslySetInnerHTML={{
                  __html: t("common_title_trial_privacy"),
                }}
                sx={{ mb: 1, textAlign: "center" }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: t("common_text_trial_privacy"),
                }}
              />
            </ContentsBox>

            <ContentsBox>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  disabled={disabeld || todo}
                  onClick={() => {
                    formSubmit();
                  }}
                >
                  {t("common_btn_submit")}
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    formReset();
                  }}
                  disabled={todo}
                >
                  {t("common_btn_reset")}
                </Button>
              </Box>
            </ContentsBox>
          </>
        )}
      </Box>
    </>
  );
};

export default Trial;
