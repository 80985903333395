import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// material ui
import { Box } from "@mui/material";

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import PageCms from "components/common/Page/PageCms";

// const

// img

// styles

const Researcher = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("研究員紹介"),
    },
    true
  );

  return (
    <>
      <Helmet>
        <title>{`${t("researcher_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("researcher_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("researcher_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath
          paths={[{ path: "/researcher", name: t("researcher_title") }]}
        />
        <PageTitle title={t("researcher_title")} />
        {article !== false && (
          <ContentsBox>
            <PageCms article={article} />
          </ContentsBox>
        )}
      </Box>
    </>
  );
};

export default Researcher;
