import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import { useLocation, Link } from "react-router-dom";

// material ui
import { Button, Box } from "@mui/material";

// component
import ContentsTitle from "components/common/Title/ContentsTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import TopList from "components/common/Liner/TopList";
// icon
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// const
import { TOP_LINER_PARAM } from "const/const";

// img

// styles

const Liner = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [library, setLibrary] = useState(null);

  useEffect(() => {
    rest(
      `Library/lib-dhefip-jw4f-gota-pnog-kcnjud5y:search`,
      "GET",
      TOP_LINER_PARAM
    )
      .then((data) => {
        setLibrary(data.data.data);
      })
      .catch(() => {
        setLibrary([]);
      });
  }, [location]);

  return (
    <>
      <ContentsBox>
        <ContentsTitle
          title={t("common_top_liner_title")}
          linkUrl="https://www.jiji.com/jc/v7?id=20190718commentliner"
          helperText={t("common_top_liner_helper")}
        />
        <TopList library={library} />
        <Box sx={{ textAlign: "center" }}>
          <Button
            to="/comment-liner/back-number"
            component={Link}
            variant="outlined"
            endIcon={<ArrowForwardIosIcon />}
            sx={[
              { p: 2, textTransform: "none", fontSize: "16px" },
              { "&:hover": { opacity: ".6" } },
            ]}
          >
            {t("common_btn_back_number")}
          </Button>
        </Box>
      </ContentsBox>
    </>
  );
};

export default Liner;
