import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import { Box, Button, Typography } from "@mui/material";

//icon
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// conponents
import Menus from "./Menus";

// module style
import styles from "./Layout.module.scss";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Box
      className={styles["footer-wrapper"]}
      sx={{
        py: 6,
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Menus color="secondary" />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Button
          color="secondary"
          href="/page/個人情報保護について"
          sx={{ display: { xs: "block", sm: "inline-flex" }, color: "#fff" }}
        >
          {t("common_privacy")}
        </Button>
        <Button
          color="secondary"
          href="/page/著作権・免責"
          sx={{ display: { xs: "block", sm: "inline-flex" }, color: "#fff" }}
        >
          {t("common_copyright")}
        </Button>
        <Button
          color="secondary"
          href="https://www.jiji.co.jp/contact?type=2&service=24"
          target="_blank"
          sx={{ display: { xs: "block", sm: "inline-flex" }, color: "#fff" }}
        >
          {t("common_contact")}
          <OpenInNewIcon
            sx={{ ml: "3px", width: "16px" }}
            fontSize="small"
            color="secondary"
          />
        </Button>
      </Box>

      <Box sx={{ p: 2, mt: 6, mx: "auto", backgroundColor: "#0e337a" }}>
        <Typography
          variant="body1"
          component="p"
          sx={{ textAlign: "center", color: "#fff" }}
          dangerouslySetInnerHTML={{ __html: t("common_footer_note") }}
        />
      </Box>

      <Typography
        variant="body2"
        component="small"
        sx={{ mt: 6, display: "block", textAlign: "center", color: "#fff" }}
      >
        {t("common_footer_copy", { date: new Date().getFullYear() })}
      </Typography>
    </Box>
  );
};

export default Footer;
