import React from "react";

// material ui
import { Box } from "@mui/material";

// component
import Loading from "../Loading/Loading";

// const

// img

// styles
import styles from "./Page.module.scss";

const PageCms = ({ article = {} }) => {
  return (
    <>
      {article === null && <Loading />}
      {article !== null && (
        <Box
          className={styles["page-cms"]}
          dangerouslySetInnerHTML={{
            __html: article.data.content_cms_entry_data.Contents,
          }}
        />
      )}
    </>
  );
};

export default PageCms;
