import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui
import { Box } from "@mui/material";

// conponents
import Helmet from "components/common/Helmet/Helmet";
import Toast from "components/common/toast/toast";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const sx = { pt: { xs: "74px", sm: "74px" } };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box>
      <Helmet>
        <title>{t("common_title")}</title>
      </Helmet>
      <Box>
        <Header />
        <Box component={"main"} sx={sx}>
          {children}
        </Box>
      </Box>
      <Footer />
      <Toast duration={5000} />
    </Box>
  );
};

export default Layout;
