import React, { useEffect, useState } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import queryString from "query-string";

// material ui
import { Box, Typography, Button } from "@mui/material";

//icon
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import Paging from "components/common/Pagination/Pagination";
import Loading from "components/common/Loading/Loading";
import BackNumberList from "components/common/Liner/BackNumberList";
import Password from "components/common/Password/Password";
import SearchForm from "components/common/Search/searchForm";

// const
import { BACK_LINER_PARAM } from "const/const";

// img

// styles
import styles from "./BackNumber.module.scss";

const BackNumber = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [thisMonth] = useState(moment());
  const [currentMonth, setCurrentMonth] = useState(
    params.ym === void 0 ? moment().format("YYYY-MM") : params.ym
  );
  const [page, setPage] = useState(
    params.p === void 0 ? 1 : parseInt(params.p)
  );

  const [library] = useRest(
    "Library/@default:search",
    {
      ...BACK_LINER_PARAM,
      page_no: page,
      query: {
        date: {
          $gte: moment(currentMonth)
            .startOf("month")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          $lte: moment(currentMonth)
            .endOf("month")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
        },
      },
    },
    true
  );

  useEffect(() => {
    setCurrentMonth(
      params.ym === void 0 ? moment().format("YYYY-MM") : params.ym
    );
    setPage(params.p === void 0 ? 1 : parseInt(params.p));
  }, [params]);

  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("バックナンバーイメージ"),
    },
    true
  );

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (article !== null) {
      setImg(
        article.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
      );
    }
  }, [article]);

  return (
    <>
      <Helmet>
        <title>{`${t("back_number_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("back_number_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("back_number_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath
          paths={[
            {
              path: "/comment-liner/back-number",
              name: t("back_number_title"),
            },
          ]}
        />
        {img !== null && (
          <PageTitle title={t("back_number_title")} bgimg={img} />
        )}
        <ContentsBox>
          <Box
            sx={{
              textAlign: "center",
              mb: 6,
            }}
          >
            <Typography
              variant="subtitle1"
              color="#3c45bb"
              component="h2"
              sx={{ fontWeight: "bold" }}
              dangerouslySetInnerHTML={{
                __html: t("common_back_number_info_text"),
              }}
            ></Typography>
          </Box>
          <Box>
            <SearchForm />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 6,
            }}
          >
            <Button
              className={styles["back-btn-sp"]}
              component={Link}
              to={`/comment-liner/back-number?ym=${moment(currentMonth)
                .add(1, "month")
                .format("YYYY-MM")}`}
              disabled={moment(thisMonth).format("YYYY-MM") === currentMonth}
              variant="contained"
            >
              <ArrowLeftIcon />
            </Button>
            <Button
              className={styles["back-btn-pc"]}
              component={Link}
              to={`/comment-liner/back-number?ym=${moment(currentMonth)
                .add(1, "month")
                .format("YYYY-MM")}`}
              disabled={moment(thisMonth).format("YYYY-MM") === currentMonth}
              startIcon={<ArrowLeftIcon />}
              variant="contained"
            >
              {moment(currentMonth).add(1, "month").format("YYYY年MM月")}
            </Button>
            <Typography variant="h5" component="h2">
              {moment(currentMonth).format("YYYY年MM月")}
            </Typography>
            <Button
              className={styles["back-btn-sp"]}
              component={Link}
              to={`/comment-liner/back-number?ym=${moment(currentMonth)
                .add(-1, "month")
                .format("YYYY-MM")}`}
              variant="contained"
            >
              <ArrowRightIcon />
            </Button>
            <Button
              className={styles["back-btn-pc"]}
              component={Link}
              to={`/comment-liner/back-number?ym=${moment(currentMonth)
                .add(-1, "month")
                .format("YYYY-MM")}`}
              endIcon={<ArrowRightIcon />}
              variant="contained"
            >
              {moment(currentMonth).add(-1, "month").format("YYYY年MM月")}
            </Button>
          </Box>

          {library === null && <Loading />}
          {library !== null && library !== false && (
            <>
              <BackNumberList
                setCurrentItem={setCurrentItem}
                setOpen={setOpen}
                library={library.data.data}
              />
              <Paging
                setCurrentPage={setPage}
                defaultPage={page}
                count={library.paging?.page_max}
              />
            </>
          )}
        </ContentsBox>
      </Box>
      <Password
        setOpen={setOpen}
        open={open}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </>
  );
};

export default BackNumber;
