import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// material ui

// component
import ContentsTitle from "components/common/Title/ContentsTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import PageCms from "components/common/Page/PageCms";

// const

// img

// styles

const Headlines = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent(
        "%E3%82%B3%E3%83%A1%E3%83%B3%E3%83%88%E3%83%A9%E3%82%A4%E3%83%8A%E3%83%BC%E5%89%8D%E6%9C%88%E3%81%AE%E8%A6%8B%E5%87%BA%E3%81%97"
      ),
    },
    true
  );

  return (
    <>
      {article !== false && (
        <ContentsBox backgound={true}>
          <ContentsTitle title={t("common_top_liner_prev")} />
          <PageCms article={article} />
        </ContentsBox>
      )}
    </>
  );
};

export default Headlines;
