import React from "react";
import { Switch, Route } from "react-router-dom";

// Material Ui

import {
  ThemeProvider,
  StyledEngineProvider,
  CssBaseline,
} from "@mui/material";
import { Theme } from "assets/jss/Theme";

// layout
import Layout from "components/common/Layout/Layout";

// page
import Index from "views/Index/Index";
import AboutUs from "views/AboutUs/AboutUs";
import Researcher from "views/Researcher/Researcher";
import CommentLiner from "views/CommentLiner/CommentLiner";
import News from "views/News/News";
import NewsDetail from "views/News/Detail";
import Information from "views/Information/Information";
import InformationDetail from "views/Information/Detail";
import BackNumber from "views/BackNumber/BackNumber";
import NotFound from "views/404/404";
import Search from "views/Search/Search";
import Trial from "views/Trial/Trial";
import PageDetail from "views/Page/Page";

// mail

const Mail = () => {
  return <Switch></Switch>;
};

const Pages = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Index />
            </Route>
            <Route exact path="/about-us">
              <AboutUs />
            </Route>
            <Route exact path="/researcher">
              <Researcher />
            </Route>
            <Route exact path="/comment-liner">
              <CommentLiner />
            </Route>
            <Route exact path="/comment-liner/back-number">
              <BackNumber />
            </Route>
            <Route exact path="/comment-liner/search">
              <Search />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
            <Route exact path="/news/:slug">
              <NewsDetail />
            </Route>
            <Route exact path="/information">
              <Information />
            </Route>
            <Route exact path="/information/:slug">
              <InformationDetail />
            </Route>
            <Route exact path="/trial">
              <Trial />
            </Route>
            <Route exact path="/page/:slug">
              <PageDetail />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

const Core = () => {
  return (
    <Switch>
      <Route path="/mail">
        <Mail />
      </Route>
      <Route>
        <Pages />
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return <Core />;
};

export default Routing;
