import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui
import { Button } from "@mui/material";

// icon

// conponents

const Menus = ({ color = "primary" }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        color={color}
        component={Link}
        to="/"
        sx={{ display: { xs: "block", sm: "inline-flex" } }}
      >
        {t("common_home")}
      </Button>
      <Button
        color={color}
        component={Link}
        to="/about-us"
        sx={{ display: { xs: "block", sm: "inline-flex" } }}
      >
        {t("common_about")}
      </Button>
      <Button
        color={color}
        component={Link}
        to="/news"
        sx={{ display: { xs: "block", sm: "inline-flex" } }}
      >
        {t("common_news")}
      </Button>
      <Button
        color={color}
        component={Link}
        to="/comment-liner"
        sx={{ display: { xs: "block", sm: "inline-flex" } }}
      >
        {t("common_comment_liner")}
      </Button>
      <Button
        color={color}
        component={Link}
        to="/comment-liner/back-number"
        sx={{ display: { xs: "block", sm: "inline-flex" } }}
      >
        {t("common_back_number")}
      </Button>
      <Button
        color={color}
        component={Link}
        to="/information"
        sx={{ display: { xs: "block", sm: "inline-flex" } }}
      >
        {t("common_info")}
      </Button>
    </>
  );
};

export default Menus;
