import React, { useEffect, useState } from "react";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import queryString from "query-string";

// material ui
import { Box } from "@mui/material";

//icon

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import Paging from "components/common/Pagination/Pagination";
import Loading from "components/common/Loading/Loading";
import BackNumberList from "components/common/Liner/BackNumberList";
import Password from "components/common/Password/Password";
import SearchForm from "components/common/Search/searchForm";

// const
import { BACK_LINER_PARAM } from "const/const";

// img

// styles

const Search = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [currentMonth, setCurrentMonth] = useState(moment().format("YYYY-MM"));
  const [page, setPage] = useState(
    params.p === void 0 ? 1 : parseInt(params.p)
  );

  const [library] = useRest(
    "Library/@default:search",
    {
      ...BACK_LINER_PARAM,
      page_no: page,
      query: {
        all: params.keyword === void 0 ? "" : params.keyword,
        "!date": {
          $gte:
            params.from === void 0
              ? moment(currentMonth).utc().format("YYYY-MM-DD HH:mm:ss")
              : moment(params.from).utc().format("YYYY-MM-DD HH:mm:ss"),
          $lte:
            params.to === void 0
              ? moment(currentMonth).utc().format("YYYY-MM-DD HH:mm:ss")
              : moment(params.to).utc().format("YYYY-MM-DD HH:mm:ss"),
        },
      },
    },
    true
  );

  useEffect(() => {
    setCurrentMonth(
      params.ym === void 0 ? moment().format("YYYY-MM") : params.ym
    );
    setPage(params.p === void 0 ? 1 : parseInt(params.p));
  }, [params]);

  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent("バックナンバーイメージ"),
    },
    true
  );

  const [img, setImg] = useState(null);

  useEffect(() => {
    if (article !== null) {
      setImg(
        article.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
      );
    }
  }, [article]);

  return (
    <>
      <Helmet>
        <title>{`${t("search_title")} - ${t("common_title")}`}</title>
        <meta
          property="og:title"
          content={`${t("search_title")} - ${t("common_title")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("search_title")} - ${t("common_title")}`}
        />
      </Helmet>
      <Box
        sx={{
          mb: 0,
        }}
      >
        <TopicPath
          paths={[
            {
              path: "/comment-liner/back-number",
              name: t("back_number_title"),
            },
            {
              path: "/comment-liner/search",
              name: t("search_title"),
            },
          ]}
        />
        {img !== null && <PageTitle title={t("search_title")} bgimg={img} />}
        <ContentsBox>
          <Box>
            <SearchForm />
          </Box>

          {library === null && <Loading />}
          {library !== null && library !== false && (
            <>
              <BackNumberList
                setCurrentItem={setCurrentItem}
                setOpen={setOpen}
                library={library.data.data}
              />
              <Paging
                setCurrentPage={setPage}
                defaultPage={page}
                count={library.paging?.page_max}
              />
            </>
          )}
        </ContentsBox>
      </Box>
      <Password
        setOpen={setOpen}
        open={open}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
      />
    </>
  );
};

export default Search;
