import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams, Link, useHistory } from "react-router-dom";

// material ui
import { Box, Button } from "@mui/material";

//icon
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import PageCms from "components/common/Page/PageCms";
import Loading from "components/common/Loading/Loading";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import { buildDate } from "utils/BuildDate";

// const

// img

// styles
const useQuery = () => new URLSearchParams(useLocation().search);

const InformationDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { slug } = useParams();
  const query = useQuery();
  const [img, setImg] = useState(null);
  const args = {
    slug: decodeURIComponent(slug),
  };

  if (query.get("_preview")) {
    args._preview = query.get("_preview");
  }

  // page
  const [article] = useRest("Content/Cms/@news:loadSlug", args, true);

  useEffect(() => {
    if (article === false) {
      history.push("/404");
    } else if (article !== null) {
      setImg(
        article.data.content_cms_entry_data.Top_Drive_Item__ !== null
          ? article.data.content_cms_entry_data.Top_Drive_Item.Media_Image.Url
          : false
      );
    }
  }, [article]);

  return (
    <>
      {article === null && <Loading />}
      {article !== null && article !== false && (
        <>
          <Helmet>
            <title>{`${article.data.content_cms_entry_data.Title} - ${t(
              "common_title"
            )}`}</title>
            <meta
              property="og:title"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
            <meta
              property="og:site_name"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
          </Helmet>
          <Box
            sx={{
              mb: 0,
            }}
          >
            <TopicPath
              paths={[
                { path: "/information", name: t("common_info") },
                {
                  path: `/information/${article.data.content_cms_entry_data.Slug}`,
                  name: article.data.content_cms_entry_data.Title,
                },
              ]}
            />
            {img !== null && (
              <PageTitle
                title={article.data.content_cms_entry_data.Title}
                subTitle={buildDate(
                  parseInt(
                    article.data.content_cms_entry_data.Published.unixms
                  ),
                  "YYYY/MM/DD"
                )}
                bgimg={img}
              />
            )}
            <ContentsBox>
              <PageCms article={article} />
              <Box sx={{ mt: 8, display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  component={Link}
                  startIcon={<ArrowBackIosIcon />}
                  to={`/information${
                    location.state === void 0 ? "" : location.state.p
                  }`}
                  sx={[
                    { p: 2, textTransform: "none", fontSize: "16px" },
                    { "&:hover": { opacity: ".6" } },
                  ]}
                >
                  {t("cms_back_btn")}
                </Button>
              </Box>
            </ContentsBox>
          </Box>
        </>
      )}
    </>
  );
};

export default InformationDetail;
