import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

// material ui
import { Box, Typography, Button } from "@mui/material";

//icon
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// conponents
import Nav from "./Nav";
import SpMenus from "./SpMenu";

// img
import jijiLogo from "assets/img/img-logo.svg";
import logo from "assets/img/logo.svg";

// module style
import styles from "./Layout.module.scss";

const Header = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);

  const toArray =
    location.pathname === "/"
      ? {}
      : {
          to: "/",
          sx: [
            {
              fontSize: "18px",
              fontWeight: "bold",
              minWidth: "230px",
              color: "primary.main",
              textDecoration: "none",
            },
            {
              "&:hover": {
                opacity: ".6",
              },
            },
          ],
        };

  const headStyle =
    location.pathname === "/"
      ? {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 2,
          px: 2,
          position: "fixed",
          width: "100%",
          height: "74px",
          backgroundColor: "rgba(255, 255, 255, .7)",
          zIndex: "999",
        }
      : {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          py: 2,
          px: 2,
          position: "fixed",
          width: "100%",
          height: "74px",
          backgroundColor: "rgba(255, 255, 255, .7)",
          zIndex: "999",
        };

  return (
    <>
      <Box
        className={clsx(styles["sp-menu"], {
          [styles["sp-menu-active"]]: openMenu,
        })}
      >
        <SpMenus openMenu={openMenu} setOpenMenu={setOpenMenu} />
        <Button
          href="https://www.jiji.com/"
          target="_blank"
          sx={{
            ml: 0,
            display: {
              fontSize: "18px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            },
            color: "#fff",
          }}
        >
          <img src={jijiLogo} width="90px" alt="" />
          <OpenInNewIcon sx={{ ml: "3px" }} fontSize="small" color="primary" />
        </Button>
      </Box>
      <Box sx={headStyle}>
        <Typography
          variant="h1"
          component={location.pathname === "/" ? "h1" : Link}
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            minWidth: "230px",
            color: "primary.main",
          }}
          {...toArray}
        >
          <img src={logo} alt={t("common_title")} />
        </Typography>
        <Nav openMenu={openMenu} setOpenMenu={setOpenMenu} />
      </Box>
    </>
  );
};

export default Header;
