import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useLocation, useParams, useHistory } from "react-router-dom";

// material ui
import { Box } from "@mui/material";

//icon

// component
import Helmet from "components/common/Helmet/Helmet";
import TopicPath from "components/common/TopicPath/TopicPath";
import PageTitle from "components/common/Title/PageTitle";
import PageCms from "components/common/Page/PageCms";
import Loading from "components/common/Loading/Loading";
import ContentsBox from "components/common/ContentsBox/ContentBox";

// const

// img

// styles
const useQuery = () => new URLSearchParams(useLocation().search);

const PageDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { slug } = useParams();
  const query = useQuery();
  const args = {
    slug: decodeURIComponent(slug),
  };

  if (query.get("_preview")) {
    args._preview = query.get("_preview");
  }

  // page
  const [article] = useRest("Content/Cms/@page:loadSlug", args, true);

  useEffect(() => {
    if (article === false) {
      history.push("/404");
    }
  }, [article]);

  return (
    <>
      {article === null && <Loading />}
      {article !== null && article !== false && (
        <>
          <Helmet>
            <title>{`${article.data.content_cms_entry_data.Title} - ${t(
              "common_title"
            )}`}</title>
            <meta
              property="og:title"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
            <meta
              property="og:site_name"
              content={`${article.data.content_cms_entry_data.Title} - ${t(
                "common_title"
              )}`}
            />
          </Helmet>
          <Box
            sx={{
              mb: 0,
            }}
          >
            <TopicPath
              paths={[
                {
                  path: `/page/${article.data.content_cms_entry_data.Slug}`,
                  name: article.data.content_cms_entry_data.Title,
                },
              ]}
            />
            <PageTitle title={article.data.content_cms_entry_data.Title} />
            <ContentsBox>
              <PageCms article={article} />
            </ContentsBox>
          </Box>
        </>
      )}
    </>
  );
};

export default PageDetail;
