import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

// material ui

// component
import ContentsTitle from "components/common/Title/ContentsTitle";
import ContentsBox from "components/common/ContentsBox/ContentBox";
import PageCms from "components/common/Page/PageCms";

// const

// img

// styles

const ExecutiveIntroduction = () => {
  const { t } = useTranslation();

  const [article] = useRest(
    "Content/Cms/@page:loadSlug",
    {
      slug: decodeURIComponent(
        "%E6%99%82%E4%BA%8B%E7%B7%8F%E5%90%88%E7%A0%94%E7%A9%B6%E6%89%80-%E5%BD%B9%E5%93%A1%E7%B4%B9%E4%BB%8B"
      ),
    },
    true
  );

  return (
    <>
      {article !== false && (
        <ContentsBox>
          <ContentsTitle title={t("about_exe_title")} />
          <PageCms article={article} />
        </ContentsBox>
      )}
    </>
  );
};

export default ExecutiveIntroduction;
